import React, { useEffect, useState } from 'react';
import logo from './logo.png';

function App() {

	const DateDifference = (endingDate) => {
		const dt = new Date();
		const startDateRaw = new Date(dt.toLocaleString('en-US', { timeZone: 'Europe/Amsterdam' }));
		let startDate = new Date(new Date(startDateRaw).toISOString());
		let endDate = new Date(endingDate);

		if (startDate > endDate) {
			const swap = startDate;
			startDate = endDate;
			endDate = swap;
		}

		const startYear = startDate.getFullYear();
		const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
		const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

		let monthDiff = endDate.getMonth() - startDate.getMonth();

		if (monthDiff < 0) {
			monthDiff += 12;
		}

		let dayDiff = endDate.getDate() - startDate.getDate();

		if (dayDiff < 0) {
			if (monthDiff > 0) {
				monthDiff--;
			} else {
				monthDiff = 11;
			}
			dayDiff += daysInMonth[startDate.getMonth()];
		}

		let hourDiff = endDate.getHours() - startDate.getHours();

		if (hourDiff < 0) {
			if (dayDiff > 0) {
				dayDiff--;
			} else {
				dayDiff = daysInMonth[startDate.getMonth()];
			}
			hourDiff = 24 + hourDiff;
		}

		let minDiff = endDate.getMinutes() - startDate.getMinutes();

		if (minDiff < 0) {
			if (hourDiff > 0) {
				hourDiff--;
			} else {
				hourDiff = 23;
				dayDiff--;
			}
			minDiff = 60 + minDiff;
		}

		return { months: monthDiff, days: dayDiff, hours: hourDiff, minutes: minDiff };
	}

	const targetDateRaw = new Date('October 26, 2022 20:00:00');
	const [timers, setTimers] = useState(DateDifference(targetDateRaw));

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimers(DateDifference(targetDateRaw));
		}, 1000);

		return () => clearTimeout(timer);
	});

	return (
		<div className='App'>
			<header className='container'>
				<img className='logo' src={logo} alt="" />
				<h3>
					Je cadeau moet nog even op zich laten wachten
				</h3>
				<h5>
					Tijd resterend:
				</h5>
				<div className='timer'>
					<div className='timer-item'>
						<span className='timer-count'>{timers.months}</span>
						<span className='timer-label'>Maanden</span>
					</div>
					<div className='timer-item'>
						<span className='timer-count'>{timers.days}</span>
						<span className='timer-label'>Dagen</span>
					</div>
					<div className='timer-item'>
						<span className='timer-count'>{timers.hours}</span>
						<span className='timer-label'>Uren</span>
					</div>
					<div className='timer-item'>
						<span className='timer-count'>{timers.minutes}</span>
						<span className='timer-label'>Minuten</span>
					</div>
				</div>
			</header>
		</div>
	);
}

export default App;
